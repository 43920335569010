<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { submitForm } from '@formkit/core'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { LoginResetPasswordProps } from './LoginResetPassword.props'
import type { SidebarFooterProps } from '@design-system/components/Organisms/Sidebar/OrganismsSidebarFooter.props'
import LoginResetPasswordCustomerServiceSide from './LoginResetPasswordCustomerServiceSide.vue'

type FormData = {
  reset_email_sideslide?: string
}

const props = withDefaults(defineProps<LoginResetPasswordProps>(), {
  isCheckout: false,
})

const resetEmail = ref<FormData>({
  reset_email_sideslide: props.defaultEmail,
})

watch(
  () => props.defaultEmail,
  newVal => {
    resetEmail.value.reset_email_sideslide = newVal
  }
)

const { emailSent, sendEmail } = useResetPassword()
const { checkTokenReCAPTCHA } = useRecaptchaSite()
const { isDialogOpen: isNestedSidebarOpen, openDialog } = useDialog(
  SIDEBAR_IDS.loginResetPasswordCustomerServiceSide
)

const formResetPassword = ref()

const domain = window?.location?.origin || ''
const { getLocale, ts } = useI18n()
const locale = getLocale()
const currentDomain = computed(() => {
  return `${domain}/${locale}`
})
const { isLoading, success } = useFormKitState(['reset-password-form'])

const submit = async (formValue: FormData) => {
  if (!formValue?.reset_email_sideslide) return
  await checkTokenReCAPTCHA('LoginResetPassword')
  await sendEmail(
    formValue?.reset_email_sideslide?.toLowerCase(),
    currentDomain.value
  )
  sendAgain.value = true
  startTimer()
}

const timerCount = ref(30)
const sendAgain = ref(false)

const startTimer = () => {
  const { pause } = useIntervalFn(() => {
    if (timerCount.value <= 30 && timerCount.value > 1) {
      timerCount.value--
    } else {
      sendAgain.value = false
      timerCount.value = 30
      pause()
    }
  }, 1000)
}

const sendLinkAgain = async () => {
  if (!resetEmail?.value?.reset_email_sideslide) return
  sendAgain.value = true
  await checkTokenReCAPTCHA('LoginResetPassword')
  sendEmail(
    resetEmail?.value?.reset_email_sideslide.toLowerCase(),
    currentDomain.value
  )
  startTimer()
}

const footerProps = computed<SidebarFooterProps>(() => ({
  primaryText: ts('myAccount.forgottenPassword.cta'),
  isPrimaryLoading: isLoading.value,
  isPrimarySuccess: success.value,
}))
</script>
<template>
  <div>
    
<FormKitLazyProvider config-file="true">
<OrganismsSidebarSlide
      :id="SIDEBAR_IDS.loginResetPassword"
      :header-props="{
        titleText: $ts('myAccount.forgottenPassword.forgotPassword'),
        hasBackButton: !isCheckout,
        hasCloseButton: false,
      }"
      :force-open="isNestedSidebarOpen"
      :footer-props="emailSent ? undefined : footerProps"
      :is-alpha-layer-transparent="!isCheckout"
      @primary-click="submitForm('reset-password-form')"
    >
      <template #body>
        <div v-if="!emailSent" class="gap-sm flex flex-col">
          <p class="text-light-6">
            {{ $ts('myAccount.forgottenPassword.resetLink') }}
          </p>
          <div>
            <FormKit
              id="reset-password-form"
              ref="formResetPassword"
              v-model="resetEmail"
              type="form"
              :actions="false"
              :incomplete-message="false"
              @submit="submit"
            >
              <InputsTextFields
                type="text"
                :label="$ts('myAccount.login.email')"
                name="reset_email_sideslide"
                :classes="{ outer: 'mb-6' }"
                validation="required|email"
                validation-visibility="submit"
                :validation-messages="{
                  required: $ts('generic.requiredEmail'),
                  email: $ts('generic.invalidEmail'),
                }"
                :show-remove-button="!!resetEmail && !emailSent"
              />
            </FormKit>
          </div>
        </div>
        <div v-else class="gap-lg text-light-6 flex flex-col">
          <p>{{ $ts('myAccount.forgottenPassword.linkSent') }}</p>
          <p>
            {{ $ts('myAccount.forgottenPassword.didntGet') }}
            <AtomsCta
              anatomy="link"
              :class="{ 'text-text-tertiary !opacity-100': sendAgain }"
              :disabled="sendAgain"
              @click="sendLinkAgain"
            >
              <template #label>
                {{ $ts('myAccount.forgottenPassword.sendAgain') }}
              </template>
            </AtomsCta>
            <span v-if="sendAgain" class="text-book-6">
              ({{ timerCount }})
            </span>
          </p>
          <p>
            {{ $ts('myAccount.forgottenPassword.contact') }}
            <AtomsCta
              anatomy="link"
              @click="openDialog(SIDEBAR_IDS.cartContactUsSideSlide)"
            >
              <template #label>
                {{ $ts('myAccount.forgottenPassword.customerService') }}
              </template>
            </AtomsCta>
          </p>
        </div>
        <LoginResetPasswordCustomerServiceSide />
      </template>
    </OrganismsSidebarSlide>
</FormKitLazyProvider>

  </div>
</template>
